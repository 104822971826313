@mixin ionicon($icon: null) {
    @if $icon != null {
        content: $icon;
    }
    display: inline-block;
    font: normal normal normal 14px/1 Ionicons;
    font-size: inherit;
    line-height: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@mixin font-awesome($icon: null) {
    @if $icon != null {
        content: $icon;
    }
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    line-height: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@mixin aspect-ratio($width, $height) {
    $width: strip-units($width);
    $height: strip-units($height);
    height: 0;
    padding: 0;
    padding-bottom: ($height / $width) * 100%;
}

@mixin media-min($width) {
    @media only screen and (min-width: #{$width}) {
        @content;
    }
}
@mixin media-max($width) {
    @media only screen and (max-width: #{$width}) {
        @content;
    }
}
@mixin media-range($min, $max) {
    @media only screen and (min-width: #{$min}) and (max-width: #{$max}) {
        @content;
    }
}

@mixin center($axis: null) {
    $transY: 0;
    $transX: 0;
    @if($axis == null or $axis == 'y'){
        top: 50%;
        $transY: -50%;
    }
    @if($axis == null or $axis == 'x'){
        left: 50%;
        $transX: -50%;
    }
    @include transform(translate($transX,$transY));
}

@mixin menu-count-items($count) {
    $str: '';
    @while str_length($str) < $count {
        $str: #{$str+'I'};
    }
    &[class*="count-#{$str}"] { // .count-(I*12)
        @content;
    }
}

@mixin double-border($color){
    position: relative;
    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 1px;
        border-top: 1px solid #ffffff;
        border-bottom: 1px solid #ffffff;
        background: $color;
    }
}

@mixin big-characs {
    > * {
        margin: 0 10px;
    }
    &:before {
        width: calc(100% - 40px);
        max-width: 455px;
    }
    .comments:after {
        content: ' Comentários';
    }
    .like-count:after {
        content: ' Curtidas';
    }
    .tags:after {
        content: ' tag';
    }
    .tags[data-multi="1"]:after {
        content: ' tags';
    }
}