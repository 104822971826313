$black: #25282d;
$orange: #f46f08;
$darkgreen: #86a533;
$green: #9ecd25;
$red: #d85d5d;
$bg-color: #fff;

$max-width: 1180px;

$lato: Lato, 'Open Sans', sans-serif;
$gotham: Gotham, 'Open Sans', sans-serif;
$museosans: 'Museo Sans', 'Open Sans', sans-serif;

$phone: 680px;
$tablet: 800px;