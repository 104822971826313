@include font-face('Museo Sans','../fonts/MuseoSans/MuseoSans-100',100,normal);
@include font-face('Museo Sans','../fonts/MuseoSans/MuseoSans-100italic',100,italic);
@include font-face('Museo Sans','../fonts/MuseoSans/MuseoSans-300',300,normal);
@include font-face('Museo Sans','../fonts/MuseoSans/MuseoSans-300italic',300,italic);
@include font-face('Museo Sans','../fonts/MuseoSans/MuseoSans-500',500,normal);
@include font-face('Museo Sans','../fonts/MuseoSans/MuseoSans-500italic',500,italic);
@include font-face('Museo Sans','../fonts/MuseoSans/MuseoSans-700',700,normal);
@include font-face('Museo Sans','../fonts/MuseoSans/MuseoSans-700italic',700,italic);
@include font-face('Museo Sans','../fonts/MuseoSans/MuseoSans-900',900,normal);
@include font-face('Museo Sans','../fonts/MuseoSans/MuseoSans-900italic',900,italic);

@include font-face('Gotham','../fonts/Gotham/Gotham-Thin',100,normal);
@include font-face('Gotham','../fonts/Gotham/Gotham-ThinItalic',100,italic);
@include font-face('Gotham','../fonts/Gotham/Gotham-XLight',200,normal);
@include font-face('Gotham','../fonts/Gotham/Gotham-XLightItalic',200,italic);
@include font-face('Gotham','../fonts/Gotham/Gotham-Light',300,normal);
@include font-face('Gotham','../fonts/Gotham/Gotham-LightItalic',300,italic);
@include font-face('Gotham','../fonts/Gotham/Gotham-Medium',400,normal);
@include font-face('Gotham','../fonts/Gotham/Gotham-MediumItalic',400,italic);
@include font-face('Gotham','../fonts/Gotham/Gotham-Bold',700,normal);
@include font-face('Gotham','../fonts/Gotham/Gotham-BoldItalic',700,italic);

@include font-face('Gotham Book','../fonts/Gotham/Gotham-Book',300,normal);
@include font-face('Gotham Book','../fonts/Gotham/Gotham-BookItalic',300,italic);


@include font-face('Gotham Rounded','../fonts/Gotham/GothamRounded-Thin',100,normal);
@include font-face('Gotham Rounded','../fonts/Gotham/GothamRounded-ThinItalic',100,italic);
@include font-face('Gotham Rounded','../fonts/Gotham/GothamRounded-XLight',200,normal);
@include font-face('Gotham Rounded','../fonts/Gotham/GothamRounded-XLightItalic',200,italic);
@include font-face('Gotham Rounded','../fonts/Gotham/GothamRounded-Light',300,normal);
@include font-face('Gotham Rounded','../fonts/Gotham/GothamRounded-LightItalic',300,italic);
@include font-face('Gotham Rounded','../fonts/Gotham/GothamRounded-Medium',400,normal);
@include font-face('Gotham Rounded','../fonts/Gotham/GothamRounded-MediumItalic',400,italic);
@include font-face('Gotham Rounded','../fonts/Gotham/GothamRounded-Bold',700,normal);
@include font-face('Gotham Rounded','../fonts/Gotham/GothamRounded-BoldItalic',700,italic);

@include font-face('Gotham Rounded Book','../fonts/Gotham/GothamRounded-Book',300,normal);
@include font-face('Gotham Rounded Book','../fonts/Gotham/GothamRounded-BookItalic',300,italic);