html {
    &, body {
        -webkit-tap-highlight-color: transparent;
        display: block;
        position: relative;
        height: 100%;
        margin: 0;
        padding: 0;
        color: #000;
        font-size: 15px;
        font-weight: 400;
        line-height: 1.2;
        font-family: $lato;
        box-sizing: border-box;
        background: #fff;
        @include media-max($phone) {
            font-family: $gotham;
        }
        * {
            outline: none;
            margin: 0;
            padding: 0;
            color: inherit;
            font: inherit;
            text-decoration: none;
            box-sizing: inherit;
            &::-moz-focus-inner {
                border: 0;
            }
        }
        h1, h2, h3, h4, h5, h6 {
            font-weight: inherit;
        }
        strong {
            font-weight: 600;
        }
        em {
            font-style: italic;
        }
        input, textarea {
            @include placeholder {
                color: inherit;
            }
        }
        h3 {
            margin: 20px 0;
            font-size: 20px;
            font-weight: 700;
            text-transform: uppercase;
            @include media-max($phone) {
                color: $green;
            }
        }
        iframe {
            display: block;
        }
    }
}

body {
    overflow-y: scroll;
}

.main-wrap {
    display: block;
    position: relative;
    width: 100%;
    max-width: 1920px;
    overflow-x: hidden;
    margin: 0 auto;
    background: $bg-color;
    box-shadow: 0 0 5px 1px rgba(#000,.2);
    .content-wrap {
        display: block;
        position: relative;
    }
}

.container {
    display: block;
    width: 100%;
    max-width: $max-width;
    margin: 0 auto;
}

.FormInput {
    display: inline;
    &.-hasError .FormError {
        color: $red;
    }
}

.fa {
    display: initial;
    font: inherit;
    line-height: 1;
    &:before {
        @include font-awesome();
    }
    &.fa-spin {
        display: inline-block;
    }
}

.black { color: $black; }
.orange { color: $orange; }
.darkgreen { color: $darkgreen; }
.green { color: $green; }
.red { color: $red; }

.upper { text-transform: uppercase; }

#modal-root {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: calc(100% + 40px);
    height: 100%;
    opacity: 0;
    z-index: 1000;
    overflow: hidden;
    overflow-y: scroll;
    padding-right: 40px;
    pointer-events: none;
    background: rgba(#000,.75);
    @include transition(opacity .2s ease);
    &:not(:empty) {
        opacity: 1;
        pointer-events: all;
        @include transition(opacity 1s ease);
        @include media-max($phone) {
            border: 0;
        }
    }

    #react-root {
        .slick-dots {
            top: 100%;
            bottom:auto;
            li {
                width: auto;
                height: auto;
                margin: 20px 5px;
                button {
                    display: block;
                    width: 10px;
                    height: 10px;
                    margin: 0;
                    padding: 0;
                    border-radius: 100%;
                    font-size: 0;
                    background: $green;
                    &:before {
                        display: none;
                    }
                }
                &.slick-active button {
                    background-color: $orange;
                }
            }
            @include media-max($phone) {
                li {
                    margin: 15px 5px;
                }
            }
        }
    }
}